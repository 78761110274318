<template>
  <v-card
    class="welcome-card pa-6 elevation-0"
    min-width="300px"
    max-width="450px"
    width="450px"
  >
    <v-card-title class="justify-center primary--text">
      Welcome to iAdOps
    </v-card-title>

    <v-card-subtitle class="text-center font-weight-medium">
      <v-col>
        <v-row>
          <v-col class="pb-0">
            <span>{{ firstName }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0">
            <span>{{ userEmail }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters, mapState: mapAuthState } = createNamespacedHelpers('auth')

export default {
  name: 'WelcomeCard',
  components: {
  },
  data() {
    return {
      dismissed: null,
    }
  },
  computed: {
    ...mapAuthGetters([
      'alert',
      'firstName',
      'userEmail',
    ]),
    ...mapAuthState({
      user: (state) => state.user,
    }),
  },
  methods: {
    goToDocs() {
      this.$router.push('/docs/releases')
      this.dismissFeaturePrompt()
    },
    async dismissFeaturePrompt() {
      this.dismissed = true

      const { user } = this
      const { email, personEmail, settings = {} } = user
      const alert = { dismissed: true }
      const doc = { settings: { ...settings, alert } }
      const docId = email || personEmail
      const data = { doc, docId }

      return this.$store.dispatch('auth/updateUserSettings', data)
    },
  },
}
</script>
